@charset "utf-8";

// Bulma overrides
@import "~bulma/sass/utilities/functions";

$primary: #110544;
$secondary: #34BF69 !default;
$secondary-invert: findColorInvert($secondary) !default
$custom-colors: ("secondary": ($secondary, $secondary-invert));

@import "~bulma/bulma";
@import "~rbx/rbx";

.current-amount-counter {
  font-size: 3rem;
}
